import { strings } from '@constants'
import { paths } from '@routes'

export const adminEditPageBreadCrumbs = {
	title: 'Edit Enquiry',
	breadCrumbs: [{ label: 'Manage Enquiries', href: paths.admin_manage_enquiry }],
}

export const adminViewPageBreadCrumbs = {
	title: 'View Enquiry',
	breadCrumbs: [{ label: 'Manage Enquiries', href: paths.admin_manage_enquiry }],
}

export const adminManageEnquiryPageBreadCrumbs = {
	title: 'Manage Enquiry',
	breadCrumbs: [{ label: 'Manage Enquiries', href: paths.admin_manage_enquiry }],
}

export const supplierQuotesManageEnquiryBreadcrumbs = (isNew, backHandler = () => { }) => ({
	title: isNew ? 'Add Supplier Quote' : 'Edit Supplier Quote',
	breadCrumbs: [
		{ label: 'Manage Enquiries', href: paths.admin_manage_enquiry },
		{ label: `Manage Enquiry`, onClick: () => backHandler(true) },
	],
	backHandler,
})

export const adminManageOrderBreadcrumbs = {
	title: 'Manage Order',
	breadCrumbs: [{ label: 'Manage Enquiry Orders', href: paths.admin_manage_enquiry_order_status('active') }],
}

export const supplierQuotesManageOrderBreadcrumbs = (isNew, orderId, backHandler = () => { }) => ({
	title: isNew ? 'Add Supplier Details' : 'Edit Supplier Details',
	breadCrumbs: [
		{ label: 'Manage Enquiry Orders', href: paths.admin_manage_enquiry_order_status('active') },
		{ label: `Manage Order`, onClick: backHandler },
	],
	backHandler,
})

export const adminEditAuctionPageBreadCrumbs = {
	title: 'Edit Auction',
	breadCrumbs: [{ label: 'Manage auctions', href: paths.admin_manage_auction }],
}

export const adminAuctionViewPageBreadCrumbs = {
	title: 'View Auction',
	breadCrumbs: [{ label: 'Manage auctions', href: paths.admin_manage_auction }],
}

export const adminManageAuctionPageBreadCrumbs = {
	title: 'Manage Auction',
	breadCrumbs: [{ label: 'Manage Auctions', href: paths.admin_manage_auction }],
}

export const buyerQuoteManageAuctionBreadcrumbs = (isNew, auctionId, backHandler = () => { }) => ({
	title: isNew ? 'Add buyer quote' : 'Edit buyer quote',
	breadCrumbs: [
		{ label: 'Manage Auctions', href: paths.admin_manage_enquiry },
		{ label: `Manage Auction`, onClick: backHandler },
	],
	backHandler,
})

export const adminCreateCloudFactoryBreadcrumbs = {
	title: strings('add_new_factory'),
	breadCrumbs: [
		{
			label: strings('factories'),
			href: paths.admin_cloud_factories,
		},
	],
}

export const adminEditCloudFactoryBreadcrumbs = (factoryId) => ({
	title: `${strings('edit_factory')} - ${factoryId}`,
	breadCrumbs: [
		{
			label: strings('factories'),
			href: paths.admin_cloud_factories,
		},
	],
})

export const adminCreateCloudMachineBreadcrumbs = {
	title: strings('add_new_machine_template'),
	breadCrumbs: [
		{
			label: strings('machines'),
			href: paths.admin_cloud_machines,
		},
	],
}

export const adminEditCloudMachineBreadcrumbs = (machineId) => ({
	title: `${strings('edit_machine_template')} - ${machineId}`,
	breadCrumbs: [
		{
			label: strings('machines'),
			href: paths.admin_cloud_machines,
		},
	],
})

export const editUserProfileBreadcrumb = (userId) => ({
	title: `${strings('edit', 'user')} - ${userId}`,
	breadCrumbs: [{ label: strings('admins'), href: paths.admin_view_admins }],
})

export const adminAddMachineToFactoryBreadcrumbs = (edit, backHandler = () => { }) => ({
	title: 'Add Machine',
	breadCrumbs: [
		{ label: 'Factories', href: paths.admin_cloud_factories },
		{ label: edit ? 'Edit Factory' : 'Add New Factory', onClick: backHandler },
	],
	backHandler,
})

export const adminEditFactoryMachineBreadcrumbs = (edit, backHandler = () => { }) => ({
	title: 'Edit Machine',
	breadCrumbs: [
		{ label: 'Factories', href: paths.admin_cloud_factories },
		{ label: edit ? 'Edit Factory' : 'Add New Factory', onClick: backHandler },
	],
	backHandler,
})

export const adminAddInventoryOfFactoryBreadcrumbs = (edit, backHandler = () => { }) => ({
	title: 'Add Inventory',
	breadCrumbs: [
		{ label: 'Factories', href: paths.admin_cloud_factories },
		{ label: edit ? 'Edit Factory' : 'Add New Factory', onClick: backHandler },
	],
	backHandler,
})

export const adminEditInventoryOfFactoryBreadcrumbs = (edit, backHandler = () => { }) => ({
	title: 'Edit Inventory',
	breadCrumbs: [
		{ label: 'Factories', href: paths.admin_cloud_factories },
		{ label: edit ? 'Edit Factory' : 'Add New Factory', onClick: backHandler },
	],
	backHandler,
})

export const adminCreateFactoryOrder = {
	title: 'Create Order',
	breadCrumbs: [
		{
			label: 'Cloud Orders',
			href: paths.admin_cloud_orders,
		},
	],
}

export const adminManageCloudOrder = (orderId) => ({
	title: `Manage Order ${orderId}`,
	breadCrumbs: [
		{
			label: 'Cloud Orders',
			href: paths.admin_cloud_orders,
		},
	],
})

export const adminToolsEndorsement = {
	title: strings('tc', 'endorsement'),
	breadCrumbs: [{ label: strings('tools'), href: paths.admin_tools_all }],
}

export const adminLogisticsCalculator = {
	title: strings('logistics', 'calculator'),
	breadCrumbs: [{ label: strings('tools'), href: paths.admin_tools_all }],
}

export const adminToolsInvoiceEndorsement = {
	title: strings('invoice', 'endorsement'),
	breadCrumbs: [{ label: strings('tools'), href: paths.admin_tools_all }],
}

export const adminToolsDistanceCalculator = {
	title: strings('distance', 'calculator'),
	breadCrumbs: [{ label: strings('tools'), href: paths.admin_tools_all }],
}

export const adminToolsIFCCalculator = {
	title: strings('ifc', 'calculator'),
	breadCrumbs: [{ label: strings('tools'), href: paths.admin_tools_all }],
}

export const adminToolsDataCorrection = {
	title: strings('data', 'correction'),
	breadCrumbs: [{ label: strings('tools'), href: paths.admin_tools_all }],
}

export const adminBanner = {
	title: strings('banner'),
	breadCrumbs: [{ label: strings('content'), href: paths.admin_content_all }],
}

export const adminBlog = {
	title: strings('blog'),
	breadCrumbs: [{ label: strings('content'), href: paths.admin_content_all }],
}

export const adminNews = {
	title: strings('news'),
	breadCrumbs: [{ label: strings('content'), href: paths.admin_content_all }],
}

export const adminOrganisation = (orgId, key) => ({
	title: (
		<span>
			{strings('organisation', key)} (
			<small>
				<b>{orgId}</b>
			</small>
			)
		</span>
	),
	breadCrumbs: [{ label: strings('organisations'), href: paths.admin_view_organisations }],
})

export const adminOrganisationEditUser = (orgId, userId) => ({
	title: (
		<span>
			{strings('user')} (
			<small>
				<b>{userId}</b>
			</small>
			)
		</span>
	),
	breadCrumbs: [
		{ label: strings('organisations'), href: paths.admin_view_organisations },
		{ label: strings('all', 'users'), href: paths.admin_organisation_users(orgId) },
	],
})

// Non Admin
export const myPeerBreadCrumb = (userId) => ({
	title: (
		<span>
			Peer (
			<small>
				<b>{userId}</b>
			</small>
			)
		</span>
	),
	breadCrumbs: [
		{ label: strings('my_profile'), href: paths.profile },
		{ label: 'All Peers', href: paths.my_peers },
	],
})

export const myPeersBreadCrumbs = {
	title: 'All Peers',
	breadCrumbs: [{ label: strings('my_profile'), href: paths.profile }],
}

export const myOrganisationBreadCrumbs = {
	title: 'Organisation',
	breadCrumbs: [{ label: strings('my_profile'), href: paths.profile }],
}

export const editQuotationBreadCrumbs = {
	title: 'Edit Quotation',
	breadCrumbs: [{ label: 'Fullfil Enquiry', href: paths.fullfill_enquiry }],
}
export const submitQuotationBreadCrumbs = {
	title: 'Submit Quotation',
	breadCrumbs: [{ label: 'Fullfil Enquiry', href: paths.fullfill_enquiry }],
}
export const viewOrderBreadCrumbs = (isPurchase) => {
	let href = isPurchase ? paths.manage_purchase_orders : paths.manage_supply_orders

	return {
		title: `View ${isPurchase ? 'Purchase' : 'Supply'} Order`,
		breadCrumbs: [{ label: `Manage ${isPurchase ? 'Purchase' : 'Supply'} Orders`, href }],
	}
}
export const editEnquiryBreadCrumbs = () => ({
	title: 'Edit Enquiry',
	breadCrumbs: [
		{
			label: 'My Enquiries',
			href: paths.my_enquiries,
		},
	],
})

export const viewBuyerQuotesBreadcrumbs = () => ({
	title: 'Buyer Quotes',
	breadCrumbs: [{ label: 'Manage Enquiries', href: paths.my_enquiries }],
})

export const editAuctionBreadCrumbs = () => ({
	title: 'Edit Auction',
	breadCrumbs: [{ label: 'My Auctions', href: paths.my_auctions }],
})

export const viewAuctionBreadCrumbs = () => ({
	title: 'View Auction',
	breadCrumbs: [{ label: 'My Auctions', href: paths.my_auctions }],
})

export const bidOnAuctionBreadCrumbs = () => ({
	title: 'Bid',
	breadCrumbs: [{ label: 'Bid on material', href: paths.bid_auction }],
})

export const adminManageAuctionOrderBreadcrumbs = {
	title: 'Manage Auction Order',
	breadCrumbs: [{ label: 'Manage Orders', href: paths.admin_manage_auction_order_status('active') }],
}

export const buyerQuotesManageAuctionOrderBreadcrumbs = (isNew, orderId, backHandler = () => { }) => ({
	title: isNew ? 'Add Buyer Details' : 'Edit Buyer Details',
	breadCrumbs: [
		{ label: 'Manage Orders', href: paths.admin_manage_auction_order_status('active') },
		{ label: `Manage Auction Order`, onClick: backHandler },
	],
	backHandler,
})

export const fabricatorCreateCloudFactoryBreadcrumbs = {
	title: strings('add_new_factory'),
	breadCrumbs: [
		{
			label: strings('factories'),
			href: paths.fabricator_factories_all,
		},
	],
}

export const fabricatorEditCloudFactoryBreadcrumbs = (factoryId) => ({
	title: `${strings('edit_factory')} - ${factoryId}`,
	breadCrumbs: [
		{
			label: strings('factories'),
			href: paths.fabricator_factories_all,
		},
	],
})

export const addMachineToFactoryBreadcrumbs = (edit, backHandler = () => { }) => ({
	title: 'Add Machine',
	breadCrumbs: [
		{ label: 'Factories', href: paths.fabricator_factories_all },
		{ label: edit ? 'Edit Factory' : 'Add New Factory', onClick: backHandler },
	],
	backHandler,
})

export const editFactoryMachineBreadcrumbs = (edit, backHandler = () => { }) => ({
	title: 'Edit Machine',
	breadCrumbs: [
		{ label: 'Factories', href: paths.fabricator_factories_all },
		{ label: edit ? 'Edit Factory' : 'Add New Factory', onClick: backHandler },
	],
	backHandler,
})

export const viewInventoryOfFactoryBreadcrumbs = (factoryId) => ({
	title: 'View Inventory',
	breadCrumbs: [
		{ label: 'Factories', href: paths.fabricator_factories_all },
		{ label: 'Edit Factory', href: paths.fabricator_factories_edit(factoryId) },
	],
})

export const manageCloudOrder = (orderId) => ({
	title: `Manage Order ${orderId}`,
	breadCrumbs: [
		{
			label: 'Manage Orders',
			href: paths.fabricator_orders,
		},
	],
})

export const viewReportsBreadCrumb = (title) => ({
	title,
	breadCrumbs: [
		{
			label: strings('reports'),
			href: paths.admin_reports_all,
		},
	],
})
export const viewEditUserProfileBreadCrumbs = (userId, editable) => ({
	title: (
		<>
			{editable ? 'Edit' : 'View'} User{' '}
			<small>
				<b>({userId})</b>
			</small>
		</>
	),
	breadCrumbs: [
		{
			label: strings('my_profile'),
			href: paths.profile,
		},
	],
})

export const editLeadBreadCrumbs = () => ({
	title: 'Lead Detail',
	breadCrumbs: [{ label: 'Leads', href: paths.admin_lead_organisations }],
})