import { auctionStatus } from '@constants'
import getUrl from '@data/conn/get/paths'
import { User } from '@data/model'

export * from './breadCrumbs'
export * from './capitalizeEveryWord'
export * from './chartUtil'
export * from './date'
export * from './getFirebaseUser'
export * from './logout'
export * from './file'
export * from './mobile'

export const decimalSeparatedNumber = (number, options = {}) => {
	const _amount = parseFloat(number)

	if (isNaN(_amount)) return options.default ?? '-'

	return _amount.toLocaleString('en-IN', options)
}
export const displayAmount = (amount, options) => {
	return decimalSeparatedNumber(amount, {
		currency: 'INR',
		style: 'currency',
		...options,
	})
}
export const displayWeight = (amount, options) => {
	const val = decimalSeparatedNumber(amount, options)
	if (val && val !== '-') return options?.noUnit ? val : `${val} ${options?.unit ?? 'MT'}`
	return val
}

export const displayName = (user) => { 
	return user.organisation_name || user.full_name || user.organisation_id
}

export const debounce = (fn, time = 0) => {
	let _timerId
	return (...args) => {
		clearTimeout(_timerId)
		_timerId = setTimeout(() => {
			fn(...args)
		}, time)
	}
}

export const getManager = (data = [], type = 'sales') => {
	if (!data) {
		return null
	}
	let manager
	if (type === 'supply') {
		manager = data.filter((item) => item.is_primary_supply === true)
	}

	if ((manager && manager.length === 0) || type === 'sales') {
		manager = data.filter((item) => item.is_primary === true)
	}

	if (manager && manager.length > 0) {
		return new User(manager[0])
	}
	return null
}

export const removeTrailingSlash = (urlStr) => urlStr.replace(/\/$/, '')

export const getDate = (nowDate = new Date()) => {
	return ('0' + nowDate.getDate()).substr(-2) + '-' + ('0' + (nowDate.getMonth() + 1)).substr(-2) + '-' + nowDate.getFullYear()
}
export const getTime = (nowDate = new Date()) => {
	const hour = nowDate.getHours()
	const minutes = nowDate.getMinutes()

	return `${hour % 12}:${minutes < 10 ? `0${minutes}` : minutes} ${hour >= 12 ? 'pm' : 'am'}`
}

export const isValidFile = (url) => {
	return /\.(jpg|jpeg|png|webp)$/.test(url)
}

export const isAuctionEditable = (auction) => {
	return (auction.auction_status === auctionStatus.created || auction.auction_status === auctionStatus.unverified) && !isAuctionOrderPlaced(auction)
}

export const isBidAcceptable = (auction) => {
	return (
		auction.auction_status === auctionStatus.active &&
		Array.isArray(auction.all_buyer_quotes) &&
		auction.all_buyer_quotes.length > 0 &&
		auction.final_supplier_quote_submitted &&
		!auction.final_supplier_quote_accepted
	)
}

export const isAuctionOrderPlaced = (auction) => {
	return auction.auction_status === auctionStatus.orderPlaced || auction.converted_to_order
}

export const isAuctionActive = (auction) => {
	return auction.auction_status === auctionStatus.active
}

export const getInitials = (name = '') =>
	name
		.replace(/\s+/, ' ')
		.split(' ')
		.slice(0, 2)
		.map((v) => v && v.substr(0, 2).toUpperCase())
		.join('')

export const randomIdGenerator = () => Math.random().toString(36).slice(2)

export const getServerUrl = () => {
	const { REACT_APP_SERVER, REACT_APP_DEV_URL, REACT_APP_URL, REACT_APP_LOCAL_URL } = process.env

	if (window.location.href.includes('dev.metalbook.app') || REACT_APP_SERVER === 'develop') {
		return `${REACT_APP_DEV_URL}${getUrl.downloadFile}?filePath=`
	} else if (REACT_APP_SERVER === 'production') {
		return `${REACT_APP_URL}${getUrl.downloadFile}?filePath=`
	} else {
		return `${REACT_APP_LOCAL_URL}${getUrl.downloadFile}?filePath=`
	}
}

export const getPlatform = () => {
	const userAgent = window.navigator.userAgent
	let os = null

	const isIOS =
		(/iPad|iPhone|iPod/.test(userAgent) ||
			(/Mac|Mac OS|MacIntel/gi.test(userAgent) && (navigator.maxTouchPoints > 1 || 'ontouchend' in document))) &&
		!window.MSStream

	if (/Macintosh|Mac|Mac OS|MacIntel|MacPPC|Mac68K/gi.test(userAgent)) {
		os = 'Mac'
	} else if (isIOS) {
		os = 'iOS'
	} else if (/'Win32|Win64|Windows|Windows NT|WinCE/gi.test(userAgent)) {
		os = 'Windows'
	} else if (/Android/gi.test(userAgent)) {
		os = 'Android'
	} else if (/Linux/gi.test(userAgent)) {
		os = 'Linux'
	}

	return os
}

export const ifcCalculator = (_price, numberOfDays = 30, _interest = 18) => {
	const interest = _interest / 100
	const gst = 0.18
	return _price + _price * ((interest * numberOfDays) / 360) * (1 + gst)
}

export const getNumberWithCountryCode = (code, number) => {
	return code.dial_code.replace('+', '') + number
}

const underTwenty = new Map([
	[10000000, 'Crore'],
	[100000, 'Lakh'],
	[1000, 'Thousand'],
	[100, 'Hundred'],
	[90, 'Ninety'],
	[80, 'Eighty'],
	[70, 'Seventy'],
	[60, 'Sixty'],
	[50, 'Fifty'],
	[40, 'Forty'],
	[30, 'Thirty'],
	[20, 'Twenty'],
	[19, 'Nineteen'],
	[18, 'Eighteen'],
	[17, 'Seventeen'],
	[16, 'Sixteen'],
	[15, 'Fifteen'],
	[14, 'Fourteen'],
	[13, 'Thirteen'],
	[12, 'Twelve'],
	[11, 'Eleven'],
	[10, 'Ten'],
	[9, 'Nine'],
	[8, 'Eight'],
	[7, 'Seven'],
	[6, 'Six'],
	[5, 'Five'],
	[4, 'Four'],
	[3, 'Three'],
	[2, 'Two'],
	[1, 'One'],
]);


const helper = function (num) {
	if (num == 0) {
		return 'Zero';
	}
	if (num <= 20) {
		return underTwenty.get(num)
	}

	let result = []

	for (let [value, element] of underTwenty) {
		const times = Math.floor(num / value);

		if (times === 0) {
			continue;
		}

		num -= times * value

		if (times === 1 && value >= 100) {
			result.push('One', element);
			continue;
		}
		if (times === 1) {
			result.push(element)
			continue;
		}

		result.push(helper(times), element);
	}
	return result.join(' ')
};

export const numberToWords = (num) => {
	return 'Rupees ' + helper(num) + ' Only';
}